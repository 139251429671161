import React from 'react';
import Cover from '../components/Cover/Cover';
import s from './ProofOfConcept.module.css';
import ProcessItem from '../components/Process/ProcessItem';

const ProofOfConcept = () => (
  <div className={s.root}>
    <Cover
      title="Proof of concept afstudeerproject"
      subtitle="ScenTronix"
      showLink={false}
    />
    <ProcessItem
      title="Inhoudsopgave"
      in_index="1"
      template=""
      is_progress={false}
      allItems={[]}
      text="
      Lorem ipsum dolar sit amet

      - abc
      - af
      - def
      "
    />
  </div>
);

export default ProofOfConcept;
