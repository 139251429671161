import regularWoff2 from './fonts/Rubik-Regular-hint-all.woff2';
import regularWoff from './fonts/Rubik-Regular-hint-all.woff';
import boldWoff2 from './fonts/Rubik-Bold-hint-all.woff2';
import boldWoff from './fonts/Rubik-Bold-hint-all.woff';

const initFonts = () => {
  if ('fonts' in document) {
    const RubikRegular = new FontFace(
      'Rubik',
      `url(${regularWoff2}) format('woff2'), url(${regularWoff}) format('woff')`,
    );
    const RubikBold = new FontFace(
      'Rubik',
      `url(${boldWoff2}) format('woff2'), url(${boldWoff}) format('woff')`,
      {
        weight: '700',
      },
    );
    Promise.all([RubikRegular.load(), RubikBold.load()]).then(e => {
      e.forEach(font => {
        document.fonts.add(font);
      });
    });
  }
};

export default initFonts;
