import React from 'react';
import PropTypes from 'prop-types';
import s from './Breadcrumbs.module.css';

const Breadcrumbs = ({ items }) => (
  <nav className={s.root}>
    <ul className={s.list}>
      {items.map(item => (
        <li key={item} className={s.item}>
          {item}
        </li>
      ))}
    </ul>
  </nav>
);

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Breadcrumbs;
