import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';
import { NavLink } from 'react-router-dom';
import s from './Navigation.module.css';
import iconArrowRight from '../../icons/icon-arrow-right.svg';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: props.defaultMenu,
    };
  }

  render() {
    const { items, direction, fixed, sticky, current } = this.props;
    const { menu } = this.state;

    const processItems = items
      .filter(item => item.is_progress && item.in_index)
      .reduce((acc, cur) => {
        const index = acc.findIndex(item => item.title === cur.in_index);
        let isActive = false;
        if (cur.in_index === current.in_index) isActive = true;
        if (index < 0) {
          acc.push({ title: cur.in_index, isActive, items: [cur] });
        } else {
          acc[index].items.push(cur);
          if (isActive) acc[index].isActive = isActive;
        }
        return acc;
      }, []);

    const topItems = [
      { label: 'Start', id: '' },
      { label: 'Introductie', id: 'introduction' },
      { label: 'Samenvatting', id: 'summary' },
      { label: 'Over ScenTronix', id: 'about-scentronix' },
      { label: 'Mijn plek binnen ScenTronix', id: 'spot-at-scentronix' },
    ];

    const bottomItems = [
      { id: 'competentions', label: 'Competenties' },
      { id: 'sources', label: 'Bronnen' },
    ];

    return (
      <nav
        className={cN(
          s.root,
          { [s.vertical]: direction === 'vertical' },
          { [s.horizontal]: direction === 'horizontal' },
          { [s.fixed]: fixed },
          { [s.isMain]: menu === 'main' },
        )}
      >
        <div className={cN({ [s.sticky]: sticky })}>
          <button
            onClick={() =>
              this.setState({
                menu: menu === 'main' ? 'process' : 'main',
              })
            }
            type="button"
            className={s.button}
          >
            <img src={iconArrowRight} alt="" />
            <span>
              {menu === 'main' ? 'Laat proces menu zien' : 'Laat menu zien'}
            </span>
          </button>
          {menu === 'main' ? (
            <>
              <div className={cN(s.stage, s.stageCompact)}>
                <ul>
                  {topItems.map(({ label, id }) => (
                    <li key={id}>
                      <NavLink to={`/${id}`}>{label}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={cN(s.stage, s.stageCompact)}>
                <ul>
                  <li>
                    <NavLink to="/het-begin">Process</NavLink>
                  </li>
                </ul>
              </div>
              <div className={cN(s.stage, s.stageCompact)}>
                <ul>
                  {bottomItems.map(({ label, id }) => (
                    <li key={id}>
                      <NavLink to={`/${id}`}>{label}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          ) : (
            <div className={s.container}>
              {processItems.map((stage, index) => (
                <article
                  className={cN(s.stage, { [s.active]: stage.isActive })}
                  key={stage.title}
                >
                  <header>
                    <small>Fase {index + 1}</small>
                    <h2>
                      <NavLink
                        to={`/${stage.items[0].in_index
                          .toLowerCase()
                          .replace(/ /g, '-')}`}
                      >
                        {stage.title}
                      </NavLink>
                    </h2>
                  </header>
                  <ul>
                    {stage.items.map(item => {
                      if (!item.title) return null;
                      return (
                        <li key={item.id}>
                          <NavLink
                            to={`/${item.in_index
                              .toLowerCase()
                              .replace(/ /g, '-')}/${item.id}`}
                          >
                            {item.title}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </article>
              ))}
            </div>
          )}
        </div>
      </nav>
    );
  }
}

Navigation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  direction: PropTypes.string,
  fixed: PropTypes.bool,
  current: PropTypes.shape(),
  defaultMenu: PropTypes.string,
  sticky: PropTypes.bool,
};

Navigation.defaultProps = {
  direction: 'horizontal',
  fixed: false,
  current: {},
  defaultMenu: 'process',
  sticky: false,
};

export default Navigation;
