import React, { Component } from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';

import Process from '../components/Process';
import ProcessItem from '../components/Process/ProcessItem';
import ProgressItem from '../components/Process/ProgressItem';
import ProofOfConcept from './ProofOfConcept';

import scriptie from '../data/scriptie';

class App extends Component {
  state = {
    loading: false,
    data: scriptie,
  };

  async componentDidMount() {
    const cachedData = localStorage.getItem('scriptieData');
    if (cachedData)
      this.setState({ data: JSON.parse(cachedData), loading: false });
    try {
      const response = await fetch(
        'https://afstudeerscriptie-767e2.firebaseio.com/scriptie.json',
      );
      const data = await response.json();
      localStorage.setItem('scriptieData', JSON.stringify(data));
      this.setState({ data, loading: false });
    } catch (e) {
      console.info('Could not fetch new data');
    }
  }

  render() {
    const { data, loading } = this.state;
    if (loading) return 'Loading content...';
    let pageIndex = 0;
    let progressIndex = -1;
    let prevPageIndex = -1;

    const pages = data.reduce((acc, cur) => {
      if (cur.is_progress) {
        if (prevPageIndex === cur.in_index) {
          acc[progressIndex].items.push(cur);
        } else {
          progressIndex += 1;
          prevPageIndex = cur.in_index;
          acc.push({
            id: cur.id,
            in_index: cur.in_index,
            title: cur.in_index,
            is_progress: true,
            items: [cur],
          });
        }
      } else {
        acc.push(cur);
        progressIndex += 1;
      }
      return acc;
    }, []);

    return (
      <Router>
        <>
          {pages.map((item, index) => {
            let path = index === 0 ? '/' : `/${item.id}`;
            if (!item.id) return null;
            if (item.is_progress) {
              path = `/${item.in_index.toLowerCase().replace(/ /g, '-')}`;
            }
            pageIndex += 1;
            return (
              <Route
                path={path}
                key={item.id || index}
                component={routeProps => {
                  if (item.items)
                    return (
                      <ProgressItem
                        key={item.id}
                        {...routeProps}
                        allItems={data}
                        {...item}
                      />
                    );
                  return (
                    <ProcessItem
                      key={item.id}
                      {...item}
                      allItems={data}
                      pageIndex={index}
                      pageNumber={pageIndex}
                    />
                  );
                }}
                exact={!index}
              />
            );
          })}

          <Route path="/print" component={() => <Process process={data} />} />

          <Route path="/proof-of-concept" component={ProofOfConcept} />

          {/* <Route */}
          {/* path="/flow-consumer/" */}
          {/* component={() => <Flow flow={consumerFlow} />} */}
          {/* /> */}
          {/* <Route path="/flow/" component={() => <Flow flow={flow} />} /> */}
        </>
      </Router>
    );
  }
}

export default App;
