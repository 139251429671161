import React, { Component } from 'react';
import cN from 'classnames';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import s from './ProcessItem.module.css';
import Content from '../Content';
import Cover from '../Cover';
import Navigation from '../Navigation';

class ProcessItem extends Component {
  static propTypes = {
    content: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.shape(),
    ]),
    label: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string.isRequired,
    pageIndex: PropTypes.number.isRequired,
    allItems: PropTypes.arrayOf(PropTypes.shape()),
    isStory: PropTypes.bool,
    in_index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inverse: PropTypes.bool,
    image: PropTypes.string,
    sources: PropTypes.string,
  };

  static defaultProps = {
    content: null,
    label: null,
    type: null,
    allItems: null,
    isStory: false,
    in_index: null,
    inverse: false,
    image: null,
    sources: null,
  };

  render() {
    const {
      title,
      subtitle,
      template,
      inverse,
      id,
      allItems,
      text,
      image,
      sources,
      // eslint-disable-next-line camelcase
      in_index,
    } = this.props;

    if (template === 'cover') {
      return (
        <section id={id} className={s.root} data-type={template}>
          <Cover {...this.props} />
        </section>
      );
    }

    const allNavItems = allItems.filter(item => !item.is_progress);

    const navItems = allNavItems.reduce((acc, cur) => {
      if (!cur.in_index) return acc;
      if (!acc[cur.in_index]) acc[cur.in_index] = [];
      acc[cur.in_index].push(cur);
      return acc;
    }, {});

    return (
      <section
        ref={el => {
          this.$el = el;
        }}
        id={id}
        className={cN(s.root, { [s.inverse]: inverse }, { inverse })}
        data-type={template}
      >
        {['default', 'sources'].includes(template) && (
          <Navigation
            defaultMenu={!template ? 'process' : 'main'}
            current={{ id, in_index }}
            items={allItems}
            direction="vertical"
            fixed
          />
        )}
        <header className={s.header}>
          <div className={s.headerInner}>
            <h2 className={s.title}>{title}</h2>
            {subtitle && <span className={s.subtitle}>{subtitle}</span>}
          </div>
        </header>
        <div className={cN(s.line, { [s.lineInverse]: inverse })}>
          <svg className={s.linkLine}>
            <mask id={`path-${id}`}>
              <line x2="640" transform="translate(-35,0)" />
              <svg x="640">
                <path d="M-1,0 Q35,0 35,35" transform="translate(-35,0)" />
              </svg>
              <line
                x1="640"
                x2="640"
                y1="65"
                y2="100%"
                transform="translate(0,-35)"
              />
              <svg x="640" y="100%">
                <path d="M0,-1 Q0,35 35,35" transform="translate(0,-35)" />
              </svg>
              <line
                x1="640"
                x2="100%"
                y1="100%"
                y2="100%"
                transform="translate(35,0)"
              />
            </mask>
            <rect
              width="100%"
              height="110%"
              y="-5%"
              fill="url(#orange-to-pink)"
              mask={`url(#path-${id})`}
            />
          </svg>
        </div>
        {image && <img src={image} className={s.image} alt="" />}
        {text && (
          <Content inverse={inverse} sectioned>
            <ReactMarkdown source={text} escapeHtml={false} />
            {sources && (
              <div className={s.sources}>
                <h4>Bronnen:</h4>
                <ul>
                  {sources.split(', ').map(source => (
                    <li key={source}>
                      <small>{source}</small>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Content>
        )}
        {template === 'start' && (
          <Content sectioned>
            <p>
              Afstudeerproces van Wietse Neven
              <br />
              wa.neven@student.avans.nl
            </p>
            <p>3 december 2018</p>
          </Content>
        )}

        {template === 'sources' && (
          <Content sectioned>
            <ul>
              {allItems.map(item => {
                if (!item.sources) return null;
                const sourcess = item.sources.split(', ');
                return sourcess.map(source => (
                  <React.Fragment key={source}>
                    <li>{source}</li>
                  </React.Fragment>
                ));
              })}
            </ul>
          </Content>
        )}

        {template === 'index' && (
          <Content sectioned>
            <ul style={{ width: '100%', maxWidth: 'inherit' }}>
              {navItems[1].map((item, index) => (
                <li
                  key={item.id}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 25px',
                    gridGap: 10,
                  }}
                >
                  <a
                    href={`#${item.id}`}
                    style={{
                      borderBottom: `1px dotted rgba(0,0,0,0.1)`,
                      boxShadow: 'none',
                    }}
                  >
                    {item.title}
                  </a>
                  <a href={`#${item.id}`}>{index}</a>
                </li>
              ))}
              <Navigation items={allItems} />
              {navItems[2].map((item, index) => (
                <li
                  key={item.id}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 25px',
                    gridGap: 10,
                  }}
                >
                  <a
                    href={`#${item.id}`}
                    style={{
                      borderBottom: `1px dotted rgba(0,0,0,0.1)`,
                      boxShadow: 'none',
                    }}
                  >
                    {item.title}
                  </a>
                  <a href={`#${item.id}`}>{index}</a>
                </li>
              ))}
            </ul>
          </Content>
        )}
      </section>
    );
  }
}

export default ProcessItem;
