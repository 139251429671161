import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs';
import GradientHeader from '../GradientHeader';

import s from './Cover.module.css';
import iconArrowRight from '../../icons/icon-arrow-right.svg';
import avatar from '../../images/NevenWietse_square.jpg';

const Cover = ({ title, subtitle, showLink }) => (
  <GradientHeader message={<></>}>
    <Breadcrumbs
      items={['Communicatie & Multimedia Design', 'Avans Hogeschool', 'Breda']}
    />
    <h1 className={s.title}>
      {title} <span>{subtitle}</span>
    </h1>

    <div className={s.meta}>
      <div className={s.avatar}>
        <a href="https://css-tricks.com/author/chriscoyier/">
          <img
            alt=""
            src={avatar}
            className={s.avatarPhoto}
            height="80"
            width="80"
          />
        </a>
        <svg className={s.halfCircle} width="80px" height="80px">
          <use xlinkHref="#half-circle" />
        </svg>
      </div>

      <div>
        <div className={s.prefix}>Ontwerper</div>
        <div>Wietse Neven</div>
      </div>

      <div>
        <div className={s.prefix}>Begeleider</div>
        <div>Michel Witter</div>
      </div>

      <div>
        <div className={s.prefix}>Opdrachtgever</div>
        <time dateTime="">ScenTronix</time>
      </div>

      <div>
        <div className={s.prefix}>Afstudeerjaar</div>
        <div>2018/2019</div>
      </div>
    </div>
    {showLink && (
      <div className={s.link}>
        <Link to="/start">
          <span>Bekijk mijn exploratie</span>
          <img src={iconArrowRight} alt="" />
        </Link>
      </div>
    )}
  </GradientHeader>
);

Cover.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  showLink: PropTypes.bool,
};

Cover.defaultProps = {
  showLink: true,
};

export default Cover;
