import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ProcessItem from './ProcessItem';

import s from './Process.module.css';

class Process extends PureComponent {
  componentDidMount() {
    document.querySelector('html').classList.add('inverse');
  }

  render() {
    const { process } = this.props;

    return (
      <div className={s.root}>
        {/* <Line items={this.items} current={4} /> */}
        {process.map((item, index) => (
          <ProcessItem
            key={item.id}
            pageIndex={index}
            {...item}
            allItems={process}
          />
        ))}
      </div>
    );
  }
}

Process.propTypes = {
  process: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
};

export default Process;
