import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ProcessItem from './ProcessItem';
import Navigation from '../Navigation';
import s from './ProgressItem.module.css';

class ProgressItem extends Component {
  componentDidMount() {
    const {
      location: { pathname },
    } = this.props;
    this.setScrollPosition(pathname);
  }

  componentWillReceiveProps({ location: { pathname } }) {
    const {
      location: { pathname: oldPathname },
    } = this.props;
    if (pathname !== oldPathname) {
      this.setScrollPosition(pathname);
    }
  }

  setScrollPosition = pathname => {
    const params = pathname.split('/').filter(param => param !== '');
    let el = document.getElementById(params[0]);

    const toId = params[params.length - 1];
    if (params.length !== 1) {
      el = document.getElementById(toId);
    }
    if (!el) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 50);
      return;
    }
    setTimeout(() => {
      window.scrollTo(0, el.offsetTop);
    }, 50);
  };

  render() {
    const { allItems, items, id, in_index: title } = this.props;
    let arrived = false;
    let nextItem;
    allItems.forEach(item => {
      if (item.in_index === title) {
        arrived = true;
      }
      if (arrived && item.in_index !== title) {
        nextItem = item;
        arrived = false;
      }
    });

    let nextPath = nextItem.id;
    let nextTitle = nextItem.title;
    if (nextItem.is_progress) {
      nextPath = nextItem.in_index.toLowerCase().replace(/ /g, '-');
      nextTitle = nextItem.in_index;
    }

    return (
      <div className={s.root} id={title.toLowerCase().replace(/ /g, '-')}>
        <div>
          <header className={s.header}>
            <h1 className={s.title}>{title}</h1>
          </header>
          <div className={s.content}>
            {items.map((item, index) => (
              <ProcessItem
                key={item.id}
                pageIndex={index}
                allItems={allItems}
                inverse={!!(index % 2)}
                {...item}
              />
            ))}
          </div>

          <footer className={s.footer}>
            <Link to={`/${nextPath}`}>
              <small>Ga verder naar</small>
              <h2>{nextTitle}</h2>
            </Link>
          </footer>
        </div>
        <Navigation
          defaultMenu="process"
          current={{ id, in_index: title }}
          items={allItems}
          direction="vertical"
          sticky
        />
      </div>
    );
  }
}

export default ProgressItem;
