import React from 'react';
import PropTypes from 'prop-types';
import s from './GradientHeader.module.css';

const GradientHeader = ({ children, message }) => (
  <div className={s.root}>
    <header className={s.header}>
      <div className={s.headerInside}>{children}</div>
    </header>
    <div className={s.gradient}>
      <div className={s.gradientContent}>
        <p>{message}</p>
      </div>
    </div>
  </div>
);

GradientHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

GradientHeader.defaultProps = {
  message: '',
};

export default GradientHeader;
